import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { Helmet } from 'react-helmet-async'

import * as Configuration from './configuration'
import { useBrand } from './use-brand'

export function TobiqueSeal() {
  //TODO: get the seal id from BE
  const sealId = {
    playboom24: '92775e6e-a2d1-47f9-bd61-098007373747',
    casinosky: '83fbc747-08ae-490c-a51e-7b5b2a180a8a',
    paradiseplay: '4556aa75-5ed3-4fc5-a565-a278493b2f1e',
  }

  const brand = useBrand()
  const subBrand = ReactRedux.useSelector(state =>
    Configuration.getSubBrand(state.configuration)
  )
  const brandSealId = sealId[subBrand || brand]
  const scriptId = `dlag_${brandSealId.replaceAll('-', '_')}`

  React.useEffect(() => {
    if (window[scriptId]) {
      window[scriptId].init()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://${brandSealId}.seals.dlagglobal.com/dlag-seal.js`}
        />
      </Helmet>
      <div
        id={`dlag-${brandSealId}`}
        data-dlag-seal-id={brandSealId}
        data-dlag-image-size="40"
        data-dlag-image-type="basic-small"
      ></div>
    </React.Fragment>
  )
}
